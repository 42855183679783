import React, { useEffect, useState } from 'react';
// import EditText from '../../CommonComponents/EditText/EditText'
import Heading from '../../CommonComponents/Heading/Heading';
import Description from '../../CommonComponents/Description/Description';
import Button from '../../CommonComponents/Button/Button';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';
import { renderMediaComponent } from '@/components/DesignSystem/ModernTheme/utils/common';

import {
  HeroBannerParent,
  TopBannerSection,
  BottomBannerSection,
  TopSection,
  ButtonContainer,
  BottomSection,
  HeroBannerVideoBackground,
  BannerContainer,
} from './ModernHeroBanner6.styled';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFormWidget } from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';

function ModernHeroBanner6(props) {
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [heroBannerState, setHeroBannerState] = useState(
    transformWidgetProps(props?.config?.children)
  );
  const [styleConfig] = useState({
    config: {
      bannerAspectRatio: {
        desktop: 768,
        mobile: 768,
      },
      rotationSpeed: 3,
    },
  });
  const dispatch = useDispatch();
  const openForm = () => {
    dispatch(toggleFormWidget(true));
  };

  useEffect(() => {
    if (props?.config?.children) {
      setHeroBannerState(transformWidgetProps(props?.config?.children));
    }
  }, [props?.config?.children]);

  useEffect(() => {
    const video = document.querySelector('video');
    if (video) {
      video.setAttribute('playsinline', '');
    }
  }, []);
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  const renderParentContainer = (bgType) => {
    return (
      <>
        {heroBannerState?.media?.foreground?.content && (
          <TopBannerSection
            IS_MEDIA_URL={
              heroBannerState?.media?.foreground?.content.length > 0 ? true : false
            }
          >
            {heroBannerState?.media?.foreground?.content?.map(({ url }, idx) => (
              <div key={idx} className={`child_container`}>
                {renderMediaComponent(
                  {
                    type: heroBannerState?.media?.foreground?.type,
                    content: [{ url }],
                  },
                  'grid'
                )}
              </div>
            ))}
          </TopBannerSection>
        )}
        {(heroBannerState?.heading?.text ||
          heroBannerState?.description?.text ||
          heroBannerState?.button?.content) && (
          <BottomBannerSection>
            <TopSection textStyles={propStyle}>
              {heroBannerState?.heading?.text && (
                <Heading headingData={heroBannerState?.heading} />
              )}
              {heroBannerState?.description?.text && (
                <Description descriptionData={heroBannerState?.description} />
              )}
            </TopSection>
            <BottomSection>
              {heroBannerState?.button?.content && (
                <ButtonContainer>
                  {heroBannerState?.button?.content?.map((btnData, idx) => (
                    <Button
                      key={btnData?.id || idx}
                      btnData={btnData}
                      primaryButton={propStyle?.buttonPrimary}
                      secondaryButton={propStyle?.buttonSecondary}
                      callback={openForm}
                    />
                  ))}
                </ButtonContainer>
              )}
            </BottomSection>
          </BottomBannerSection>
        )}
      </>
    );
  };
  return heroBannerState?.media?.background?.type === 'video' ? (
    <HeroBannerVideoBackground
      id="MT__HEROBANNER6"
      background={heroBannerState?.media?.background}
      styleConfig={styleConfig?.config}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_hero_banner6 bg_type_${heroBannerState?.media?.background?.type}`}
    >
      <video
        autoPlay
        loop
        muted
        onError={(e) =>
          (e.target.src = heroBannerState?.media?.background?.content[0]?.url)
        }
        playsInline
      >
        <source
          src={heroBannerState?.media?.background?.content[0]?.url}
          type="video/mp4"
        />
      </video>
      <HeroBannerParent
        id="MT__HEROBANNER6"
        styleConfig={styleConfig?.config}
        propsBackground={heroBannerState?.media?.background}
        themeBackground={propStyle?.backgroundColor}
        className={`modern_theme_hero_banner6 bg_type_${heroBannerState?.media?.background?.type}`}
      >
        <BannerContainer>{renderParentContainer('video')}</BannerContainer>
      </HeroBannerParent>
    </HeroBannerVideoBackground>
  ) : (
    <HeroBannerParent
      id="MT__HEROBANNER6"
      styleConfig={styleConfig?.config}
      propsBackground={heroBannerState?.media?.background}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_hero_banner6 bg_type_${heroBannerState?.media?.background?.type}`}
    >
      {heroBannerState?.media?.background?.type === 'image' &&
      heroBannerState?.media?.background?.content[0]?.url.length > 0 ? (
        <img
          className="bg"
          src={heroBannerState?.media?.background?.content[0]?.url}
          onError={(e) =>
            (e.target.src = heroBannerState?.media?.background?.content[0]?.url)
          }
        />
      ) : (
        <></>
      )}
      <BannerContainer>{renderParentContainer('image')}</BannerContainer>
    </HeroBannerParent>
  );
}

export default ModernHeroBanner6;
