import styled from 'styled-components';
import {
  FALLBACK_COLOR,
  BODY_COLOR_OPACITY,
  BANNER_OPACITY,
} from '@/components/DesignSystem/ModernTheme/utils/common';
export const HeroBannerVideoBackground = styled.div`
  background: ${(props) =>
    !props?.propsBackground?.content[0]?.url && `none, ${props?.themeBackground}`};
  background-size: cover;
  background-position: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; /* Set the z-index behind other content */
  }

  /* Add a linear gradient overlay */
  &::after {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${BANNER_OPACITY};
    z-index: 1;
  }
  @media screen and (min-width: 768px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  @media screen and (max-width: 767px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  & > video {
    position: absolute;
    height: 100%;
  }
`;
export const HeroBannerParent = styled.div`
  &.bg_type_image {
    background: ${(props) =>
      props?.propsBackground?.content[0]?.url
        ? `url(${props?.propsBackground?.content[0]?.url})`
        : `none, ${props?.themeBackground}`};
    background-size: cover;
    background-position: center;
    & > .bg {
      position: absolute;
      height: 100%;
      object-fit: fill;
      width: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0; /* Set the z-index behind other content */
    }
    /* Add a linear gradient overlay */
    &::after {
      content: '';
      background: ${(props) => props?.themeBackground};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: ${BANNER_OPACITY};
      z-index: 1;
    }
  }

  &.bg_type_solid {
    background: ${(props) => props?.themeBackground};
  }
  &.bg_type_image::after,
  .bg_type_solid {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${BANNER_OPACITY};
    z-index: 1;
  }
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    gap: 0;
  }
  @media screen and (max-width: 767px) {
    gap: 2rem;
    padding-bottom: 2rem;
    padding-top: 1.106rem;
  }
`;
export const TopBannerSection = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
  z-index: 99;
  .child_container img {
    width: 100%;
    background-color: ${(props) =>
      props?.IS_MEDIA_URL ? 'transparent' : FALLBACK_COLOR};
  }
  @media screen and (min-width: 768px) {
    padding: 4.75rem 1rem 0 1rem;
    flex-wrap: wrap;
    & > .child_container {
      flex: 0 0 calc((100% - 2rem) / 3);
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0.894rem 0 1rem 1rem;
    flex-wrap: nowrap;
    & > .child_container {
      flex: 0 0 calc((100% - 2rem) / 1);
    }
  }

  &::-webkit-scrollbar {
    width: 0.2rem; /* Set the scrollbar width to a small value */
    height: 0.2rem; /* Set the scrollbar height to a small value */
  }

  &::-webkit-scrollbar-thumb {
    background: transparent; /* Make the scrollbar thumb transparent */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Make the scrollbar track transparent */
  }
`;
export const BottomBannerSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 99;
  @media screen and (min-width: 768px) {
    padding: 4.75rem 8.25rem;
    gap: 2.625rem;
  }
  @media screen and (max-width: 767px) {
    padding: 0 1.5rem;
    gap: 1.5rem;
  }
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
  align-self: center;
  & > h1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    color: ${(props) => props?.textStyles?.heading?.color};
    font-family: ${(props) => props?.textStyles?.heading?.fontFamily || 'Arial'};
    text-align: ${(props) => props?.textStyles?.heading?.textAlign || 'center'};
    margin: 0;
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  & > p {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-style: normal;
    margin: 0;
    color: ${(props) => props?.textStyles?.body?.color};
    text-align: ${(props) => props?.textStyles?.body?.textAlign || 'center'};
    font-family: ${(props) => props?.textStyles?.body?.fontFamily || 'Arial'};
    opacity: ${BODY_COLOR_OPACITY};
    overflow-wrap: break-word; /* Fallback for browsers that don't support 'anywhere' */
    overflow-wrap: anywhere; /* Use 'anywhere' for more flexible line breaking */
  }
  @media screen and (min-width: 768px) {
    gap: 1.5rem;
    & > h1 {
      font-size: ${(props) => props?.textStyles?.heading?.fontSize || '3.875rem'};
      font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
      line-height: 4.5rem;
      justify-content: center;
    }
    & > p {
      font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.25rem'};
      font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
      line-height: normal;
      justify-content: center;
    }
  }
  @media screen and (max-width: 767px) {
    gap: 0.75rem;
    & > h1 {
      font-size: 2rem;
      font-weight: 600;
      line-height: normal;
      justify-content: left;
    }
    & > p {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: normal;
      justify-content: left;
    }
  }
`;
export const BottomSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
    flex-direction: column;
    width: 100%;
    button {
      width: 100%;
    }
  }
`;
